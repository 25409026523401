import { render, staticRenderFns } from "./MandatoryTrainingReminders.vue?vue&type=template&id=734231d0&scoped=true"
import script from "./MandatoryTrainingReminders.vue?vue&type=script&lang=js"
export * from "./MandatoryTrainingReminders.vue?vue&type=script&lang=js"
import style0 from "./MandatoryTrainingReminders.vue?vue&type=style&index=0&id=734231d0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "734231d0",
  null
  
)

export default component.exports