<template>
  <v-card>
    <v-card-title class="justify-space-between flex-nowrap">
      <div class="d-flex align-center">
        <v-icon
          aria-hidden="true"
          class="mr-3 material-icons-outlined"
          color="#1D428A"
        >
          mdi-text-box-multiple-outline
        </v-icon>
        <div>
          <h2 class="title font-weight-bold text-subtitle-2 text-sm-h6">
            Mandatory Training Reminders
          </h2>
          <div
            class="warning--text"
            style="font-size: 1rem; line-height: 16px; font-weight: 500"
          >
            <!-- Please allow 2 working days for Q+ mandatory training data to
            refresh from MyPL. -->
          </div>
        </div>
      </div>
      <div>
        <v-btn
          aria-label="More information"
          icon
          class="d-print-none text-decoration-none size--44"
          @click="openInfoDialog = true"
        >
          <v-icon
            color="primary"
            dark
          >
            mdi-help-circle-outline
          </v-icon>
        </v-btn>
      </div>
    </v-card-title>
    <hr>
    <!--    <v-card-text
          class="text-body-1 dark&#45;&#45;text secondary"
        >
          <p class="pt-5">From <b>Day 1, Term 1 2025</b>, mandatory training is going to change. To support these changes, MyPL is currently undergoing planned maintenance and will become available as soon as all the new courses are available.</p>
          <p>Q+ will also be updated to reflect these changes.</p>
          <p>
            Visit
            <a
              href="https://education.nsw.gov.au/inside-the-department/human-resources/mandatory-training/changes-to-mandatory-training"
              target="_blank"
            >Mandatory training in 2025
            </a> for the most up-to-date information.
          </p>
        </v-card-text>-->
    <v-card-text class="pa-0">
      <v-data-table
        class="mandatory-training-table"
        :headers="mandatoryTrainingRemindersHeaders"
        :items="filteredMandatoryTrainingList"
        v-bind="$attrs"
        disable-pagination
        hide-default-footer
        :loading="loading"
      >
        <template v-slot:item.status="{ item }">
          <div
            :class="{
        'error--text font-weight-bold': item.complianceStatusCode === complianceStatus.NOT_CERTIFIED,
        'warning--text font-weight-bold': item.complianceStatusCode === complianceStatus.NOT_YET_DUE,
        'success--text font-weight-bold': item.complianceStatusCode === complianceStatus.CERTIFIED
      }"
            class="d-flex justify-end"
          >
            <v-icon
              v-if="item.complianceStatusCode === complianceStatus.NOT_CERTIFIED"
              aria-hidden="true"
              class="error--text mr-2"
            >
              mdi-alert-circle
            </v-icon>
            <v-icon
              v-else-if="item.complianceStatusCode === complianceStatus.NOT_YET_DUE"
              aria-hidden="true"
              class="warning--text mr-2"
            >
              mdi-alert-circle
            </v-icon>
            <v-icon
              v-else-if="item.complianceStatusCode === complianceStatus.CERTIFIED"
              aria-hidden="true"
              class="success--text mr-2"
            >
              mdi-check-circle
            </v-icon>
            {{ getStatusText(item.complianceStatusCode) }}
          </div>
        </template>
      </v-data-table>
    </v-card-text>
    <v-card-text class="mt-8">
      <div>
        <p class="training-info">
          Please consult the
          <a href="https://education.nsw.gov.au/inside-the-department/human-resources/mandatory-training" target="_blank" >
            Mandatory Training Hub
          </a>
          for role specific training, or contact
          <a href="#" @click.prevent="handleEmailClick">
            mypl@det.nsw.edu.au
          </a>
          for queries regarding training.
        </p>
        <p class="refresh-notice">
          Please allow 2 working days for mandatory training data to refresh from MyPL.
        </p>
      </div>
    </v-card-text>
    <Dialog
      :openDialog="openInfoDialog"
      maxWidth="600px"
      title="Mandatory Training Reminders"
      icon="info_outline"
      :displayCloseBtn="true"
      @close="closeTableInfo"
      :return-handler="closeTableInfo"
    >
      <div
        slot="text"
        class="pa-5 pl-3 pt-0 ml-16"
      >
        <p class="mb-4">Mandatory training MyPL data last updated <strong>48 hours ago</strong> (approximately).</p>
        <p class="mb-6">Mandatory training aligned to the Standards counts toward professional development (PD) hours for accreditation.</p>

        <h3 class="mb-4 ads-navy">Course status guide</h3>

        <div class="status-guide">
          <div class="status-item mb-4">
            <div class="d-flex align-center">
              <v-icon class="success--text mr-2" >mdi-check-circle</v-icon>
              <strong class="ads-navy">Certified</strong>
            </div>
            <p class="ml-8 mb-0">Course is completed and certification is current.</p>
          </div>

          <div class="status-item mb-4">
            <div class="d-flex align-center">
              <v-icon class="warning--text mr-2">mdi-alert-circle</v-icon>
              <strong class="ads-orange">Not Yet Due</strong>
            </div>
            <p class="ml-8 mb-0">Courses that need to be completed within the first 12 months of employment.</p>
          </div>

          <div class="status-item">
            <div class="d-flex align-center">
              <v-icon class="error--text mr-2">mdi-alert-circle</v-icon>
              <strong class="ads-red">Not Certified</strong>
            </div>
            <p class="ml-8 mb-0">You might need to complete the course or renew expired certification.</p>
          </div>
        </div>
      </div>
    </Dialog>
  </v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import {Dialog} from "@nswdoe/doe-ui-core";
import moment from "moment";
import _ from "lodash";

const COMPLIANCE_STATUS = {
  NOT_CERTIFIED: 'KS_SCNC',
  NOT_YET_DUE: 'KS_SCNYD',
  CERTIFIED: 'KS_SCC'
};

// Define mandatory item codes
const mandatoryItemCodes = [
  "SC_6",     // Child Protection Awareness Training
  "SC_28",    // Code of Ethics and Conduct
  "SC_3",     // Emergency Care
  "SC_16",    // WHS Induction for Employees
  "SC_20",    // Cyber Security and Data Breaches – School-based and ESS
  "SC_29",    // Public interest disclosure - Serious wrongdoing
  "SC_22",    // Aboriginal Cultural Education
  "SC_19",    // Anti-Racism Policy
  "SC_30",    // Annual Competency Check – School-based Staff
];

const nsbtsMandatoryItemCodes = [
  "CSC_2",    // Child Protection Awareness Training
  "CSC_21",   // Code of Ethics and Conduct
  "CSC_8",    // Work Health & Safety Induction for Employees
  "CSC_11",   // Cyber Security and Data Breaches – School-based and ESS
  // "CSC_15",   // Cyber Security and Data Breaches – PSSE (role dependent)
  "CSC_13",   // Public interest disclosure - Serious wrongdoing
  "CSC_14",   // Aboriginal Cultural Education
  "CSC_1",    // Anti-Racism Policy Training
  "CSC_22",   // Annual Competency Check – ESS
  // "CSC_23",   // Annual Competency Check – PSSE (Inc. DELs) (role dependent)
];

const nsbtsMandatoryItemCodes_PSSE = [
  "CSC_2",    // Child Protection Awareness Training
  "CSC_21",   // Code of Ethics and Conduct
  "CSC_8",    // Work Health & Safety Induction for Employees
  "CSC_15",   // Cyber Security and Data Breaches – PSSE (role dependent)
  "CSC_13",   // Public interest disclosure - Serious wrongdoing
  "CSC_14",   // Aboriginal Cultural Education
  "CSC_1",    // Anti-Racism Policy Training
  "CSC_23",   // Annual Competency Check – PSSE (Inc. DELs) (role dependent)
];

const PSSE_ROLES = [
  'SCHOOL.CORPORATE.PSSE1',
  'SCHOOL.CORPORATE.PSSE2',
  'SCHOOL.CORPORATE.PSSE3'
];

const MandatoryTrainingReminders = {
  data() {
    return {
      mandatoryTrainingRemindersHeaders: [
        {text: "Name", value: "itemName"},
        {text: "Status", value: "status", width: 180, sortable: false}, // Changed from dueDate to status
      ],
      loading: false,
      openInfoDialog: false,
      complianceStatus: COMPLIANCE_STATUS
    };
  },
  components: {
    Dialog,
  },
  computed: {
    ...mapGetters({
      mandatoryTrainingList: "moduleUserProfile/mandatoryTrainingList",
    }),
    ...mapState("moduleStaticContent", {
      dashboardContent: (state) => state.content.dashboard,
    }),
    filteredMandatoryTrainingList() {
      if (!this.mandatoryTrainingList?.length) return [];

      // Define status order for sorting
      const statusOrder = {
        [COMPLIANCE_STATUS.NOT_CERTIFIED]: 1,
        [COMPLIANCE_STATUS.NOT_YET_DUE]: 2,
        [COMPLIANCE_STATUS.CERTIFIED]: 3
      };

      // Get all staff accreditations from all positions
      const validAccreditations = this.mandatoryTrainingList.flatMap(position =>
        position.staffAccreditations.filter(accred =>
          Object.values(COMPLIANCE_STATUS).includes(accred.complianceStatusCode)
        )
      );

      // First try to find corporate mandatory items
      const targetCodes = this.isPSSEUser ? nsbtsMandatoryItemCodes_PSSE : nsbtsMandatoryItemCodes;
      const corpList = validAccreditations.filter(item =>
        targetCodes.includes(item.itemCode)
      );

      // If corporate items exist, use those, otherwise fall back to school items
      const targetList = corpList.length > 0
        ? corpList
        : validAccreditations.filter(item => mandatoryItemCodes.includes(item.itemCode));

      // Deduplicate (in case same training appears in multiple positions) and sort
      return _.orderBy(
        _.uniqBy(targetList, item => `${item.itemName}-${item.complianceStatusCode}`),
        [(item) => statusOrder[item.complianceStatusCode], 'itemName'],
        ['asc', 'asc']
      );
    },
    isPSSEUser() {
      const userRoles = this.$OAuth.data.profile.det_roles.split(',');
      return userRoles.some(role => PSSE_ROLES.includes(role));
    },
  },
  created() {
    this.loading = true;
    this.$store
      .dispatch("moduleUserProfile/fetchMandatoryTrainingList")
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    handleEmailClick() {
      window.location.href = 'mailto:mypl@det.nsw.edu.au';
    },
    getStatusText(complianceStatusCode) {
      switch (complianceStatusCode) {
        case COMPLIANCE_STATUS.NOT_CERTIFIED:
          return 'Not Certified';
        case COMPLIANCE_STATUS.NOT_YET_DUE:
          return 'Not Yet Due';
        case COMPLIANCE_STATUS.CERTIFIED:
          return 'Certified';
        default:
          return 'N/A';
      }
    },
    formatDate(itemDate) {
      let itemCorrectDate = itemDate.split("-");
      return (
        itemCorrectDate[2] + "/" + itemCorrectDate[1] + "/" + itemCorrectDate[0]
      );
    },
    isCloseToDate(itemDate) {
      let itemDue = moment(itemDate);
      return (
        itemDue.diff(moment(), "months") < 1 &&
        itemDue.diff(moment(), "days") > 0
      );
    },
    closeTableInfo() {
      this.openInfoDialog = false;
    },
  },
};
export default MandatoryTrainingReminders;
</script>

<style lang="scss" scoped>
.training-info {
  font-weight: 700;
  color: #4C4F55;
}

.refresh-notice {
  color: #4C4F55;
}

.ads-navy {
  color: #002664;
}

.ads-orange {
  color: #F3631B;
}

.ads-red {
  color: #D7153A;
}

.mandatory-training-table {
  // max-height: 380px;
  overflow: auto;
}

.size--44 {
  height: 44px;
  width: 44px;
}

::v-deep {
  .v-data-table-header-mobile {
    display: none;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 16px;
  }

  .v-data-table > .v-data-table__wrapper > table > thead > tr > th:last-child {
    text-align: end !important;
    color: #002664 !important;
  }
}

@media print {
  .mandatory-training-table {
    max-height: max-content;
    overflow: initial;
  }
}
</style>
